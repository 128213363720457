















import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { AuthAction, AuthForm } from "@/data/auth";
import { NotificationFactory } from "@/utils/notificationFactory";
import ModalFooter from "@/components/common/modalfooter.vue";

@Component({
  components: { ModalFooter }
})
export default class RegistrationInitComponent extends Vue {
  loading: boolean = false;
  @Inject() $validator: any;
  @Prop() form: AuthForm;

  async submit(): Promise<void> {
    this.$validator.validate("form.*").then(async (res: boolean) => {
      if (res) {
        try {
          this.loading = true;
          await this.form
            .submit(await this.form.phoneAuthRequest())
            .then(nextRoute => {
              if (this.form.action !== AuthAction.register) {
                NotificationFactory.info(
                  "Данный пользователь уже существует в системе."
                );
              }
              this.$router.push({ name: nextRoute });
            });
        } finally {
          this.loading = false;
        }
      }
    });
  }
}
